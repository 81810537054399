<template>
	<div @click.stop="">
		<!-- 顶部信息栏 -->
		<div class="edit-print-top" ref="printTop">
			<!-- 退出编辑 -->
			<div class="edit-print-exit" @click="handleBack()">
				<span class="iconfont icon-arrow-right"></span>
				<span>{{ $t(`button.exitEdit`) }}</span>
			</div>

			<!-- 模板名称 -->
			<div class="edit-print-name" @click="editLabelName()">
				<div style="display: flex; align-items: center">
					<div class="edit-print-name-text">{{ labelAttr.name }}</div>
					<span class="iconfont icon-edit"></span>
				</div>
				<!-- 尺寸 -->
				<div v-if="labelAttr.unit">
					<div v-if="labelAttr.unit == 'inch'" class="edit-print-name-size">{{ labelAttr.width }}' X {{ labelAttr.height }}'</div>
					<div v-else class="edit-print-name-size">{{ labelAttr.width + labelAttr.unit }}X{{ labelAttr.height + labelAttr.unit }}</div>
				</div>
			</div>
			<!-- 标签设置、保存、打印 -->
			<div class="top-btn">
				<el-button @click="handleOpenSet()">
					<span class="iconfont icon-biaoqian"></span>
					<span>{{ $t(`button.labelset`) }} </span>
				</el-button>
				<el-button @click="handleSave()">
					<span class="iconfont icon-save"></span>
					<span>{{ $t(`button.save`) }} </span>
				</el-button>
				<el-button @click="handlePrint()">
					<span class="iconfont icon-print"></span>
					<span>{{ $t(`button.printLabel`) }} </span>
				</el-button>
			</div>
		</div>

		<!-- 顶部工具操作栏 -->
		<div class="edit-print-tool" ref="printTool">
			<!-- 锁定 -->
			<div class="align-icon" @click="handleLock()">
				<div class="iconfont icon-suoding" :style="{ color: !selectNode ? '#bfc7d2' : '' }"></div>
				<div class="align-icon-hover">
					<div class="triangle_border_up"></div>
					<div class="align-icon-text">
						{{ selectNode && selectNode.attrs.parameter.Lock ? $t(`editAttr.attrText53`) : $t(`editAttr.attrText52`) }}
					</div>
				</div>
			</div>
			<!-- 撤销 -->
			<div class="align-icon" @click="handleUndo()">
				<div class="iconfont icon-chexiao" :style="{ color: historyIndex < 0 ? 'rgba(123, 137, 161, 0.4)' : '' }"></div>
				<div class="align-icon-hover">
					<div class="triangle_border_up"></div>
					<div class="align-icon-text">{{ $t(`editAttr.attrText69`) }}</div>
				</div>
			</div>
			<!-- 恢复 -->
			<div class="align-icon" @click="handleRestore()">
				<div
					class="iconfont icon-huifu"
					:style="{
						color: historyIndex == historyList.length - 1 || historyList.length == 0 ? 'rgba(123, 137, 161, 0.4)' : ''
					}"></div>
				<div class="align-icon-hover">
					<div class="triangle_border_up"></div>
					<div class="align-icon-text">{{ $t(`editAttr.attrText70`) }}</div>
				</div>
			</div>

			<!-- 分割线 -->
			<div class="align-icon-fenge"></div>

			<!-- 对齐操作 -->
			<div class="align-icon" v-for="(item, index) in alignList" :key="index" @click="handleAlign(item.type)">
				<template v-if="item.type == 'chuizhijianju' || item.type == 'shuipingjianju'">
					<div
						:class="['iconfont', item.icon]"
						:style="{
							color: selectionElement.length < 3 ? 'rgba(123, 137, 161, 0.4)' : ''
						}"></div>
				</template>

				<template v-else>
					<div
						:class="['iconfont', item.icon]"
						:style="{
							color: selectionElement.length == 0 && !selectNode ? 'rgba(123, 137, 161, 0.4)' : ''
						}"></div>
				</template>
				<div class="align-icon-hover">
					<div class="triangle_border_up"></div>
					<div class="align-icon-text">{{ item.text }}</div>
				</div>
			</div>

			<!-- 分割线 -->
			<div class="align-icon-fenge"></div>

			<!-- 置底 -->
			<div class="align-icon" @click="handleOrder('bottom')">
				<div
					class="iconfont icon-zhidi"
					:style="{
						color: !selectNode && selectionElement.length == 0 ? 'rgba(123, 137, 161, 0.4)' : ''
					}"></div>
				<div class="align-icon-hover">
					<div class="triangle_border_up"></div>
					<div class="align-icon-text">{{ $t(`editAttr.attrText65`) }}</div>
				</div>
			</div>
			<!-- 置顶 -->
			<div class="align-icon" @click="handleOrder('top')">
				<div
					class="iconfont icon-zhiding"
					:style="{
						color: !selectNode && selectionElement.length == 0 ? 'rgba(123, 137, 161, 0.4)' : ''
					}"></div>
				<div class="align-icon-hover">
					<div class="triangle_border_up"></div>
					<div class="align-icon-text">{{ $t(`editAttr.attrText64`) }}</div>
				</div>
			</div>

			<!-- 分割线 -->
			<div class="align-icon-fenge"></div>

			<!-- 复制 -->
			<div class="align-icon" @click="handleCopy()">
				<div
					class="iconfont icon-fuzhi"
					:style="{
						color: !selectNode && selectionElement.length == 0 ? 'rgba(123, 137, 161, 0.4)' : ''
					}"></div>
				<div class="align-icon-hover">
					<div class="triangle_border_up"></div>
					<div class="align-icon-text">{{ $t(`editAttr.attrText50`) }}</div>
				</div>
			</div>
			<!-- 粘贴 -->
			<div class="align-icon" @click="handlePaste()">
				<div class="iconfont icon-zhantie" :style="{ color: copyData.length == 0 ? 'rgba(123, 137, 161, 0.4)' : '' }"></div>
				<div class="align-icon-hover">
					<div class="triangle_border_up"></div>
					<div class="align-icon-text">{{ $t(`editAttr.attrText51`) }}</div>
				</div>
			</div>
			<!-- 删除 -->
			<div class="align-icon" @click="handleDel()">
				<div
					class="iconfont icon-shanchu"
					:style="{
						color: !selectNode && selectionElement.length == 0 ? 'rgba(123, 137, 161, 0.4)' : ''
					}"></div>
				<div class="align-icon-hover">
					<div class="triangle_border_up"></div>
					<div class="align-icon-text">{{ $t(`editAttr.attrText66`) }}</div>
				</div>
			</div>
		</div>

		<!-- 右键菜单 -->
		<div
			class="right-menu"
			id="rightMenu"
			v-show="isShowMenu"
			:style="{
				top: rightMenu.posY + 'px',
				left: rightMenu.posX + 'px'
			}"
			@click.stop=""
			@contextmenu.prevent="">
			<div class="right-menu0">
				<!-- 全选 -->
				<div class="right-menu-item" @click="handleAllSelect()">
					<div>{{ $t(`editAttr.attrText78`) }}</div>
					<div>Ctrl+A</div>
				</div>
			</div>
			<div class="right-menu1">
				<!-- 复制 -->
				<div
					class="right-menu-item"
					@click="!selectNode && selectionElement.length == 0 ? '' : handleCopy()"
					:style="{
						color: !selectNode && selectionElement.length == 0 ? '#d6dce6' : ''
					}">
					<div>{{ $t(`editAttr.attrText50`) }}</div>
					<div>Ctrl+C</div>
				</div>
				<!-- 粘贴 -->
				<div class="right-menu-item" @click="copyData.length == 0 ? '' : handlePaste()" :style="{ color: copyData.length == 0 ? '#d6dce6' : '' }">
					<div>
						{{ $t(`editAttr.attrText51`) }}
					</div>
					<div>Ctrl+V</div>
				</div>
			</div>
			<div class="right-menu2">
				<!-- 锁定解锁 -->
				<div
					class="right-menu-item"
					@click="selectNode && selectionElement.length == 0 ? handleLock() : ''"
					:style="{
						color: selectNode && selectionElement.length == 0 ? '#535d6c' : '#d6dce6'
					}">
					{{ selectNode && selectNode.attrs.parameter.Lock ? $t(`editAttr.attrText53`) : $t(`editAttr.attrText52`) }}
				</div>
				<!-- 对齐 -->
				<div
					class="right-menu-item right-menu-item-align"
					:style="{
						color: selectNode || selectionElement.length > 0 ? '#535d6c' : '#d6dce6'
					}">
					{{ $t(`editAttr.attrText54`) }}
					<i class="iconfont icon-arrow-right"></i>
					<!-- 对齐选项 -->
					<div class="right-align-menu">
						<div v-for="(item, index) in alignList" :key="index" @click="handleAlign(item.type)">
							<template v-if="item.type == 'chuizhijianju' || item.type == 'shuipingjianju'">
								<div class="right-menu-item" :style="{ color: selectionElement.length < 3 ? '#d6dce6' : '' }">
									{{ item.text }}
								</div>
							</template>

							<template v-else>
								<div
									class="right-menu-item"
									:style="{
										color: selectionElement.length == 0 && !selectNode ? '#d6dce6' : ''
									}">
									{{ item.text }}
								</div>
							</template>
						</div>
					</div>
				</div>
				<!-- 图层顺序 -->
				<div
					class="right-menu-item right-menu-item-order"
					:style="{
						color: selectNode || selectionElement.length > 0 ? '#535d6c' : '#d6dce6'
					}">
					{{ $t(`editAttr.attrText63`) }}
					<i class="iconfont icon-arrow-right"></i>
					<!-- 图层选项 -->
					<div class="right-order-menu">
						<div class="right-menu-item" @click="handleOrder('top')">
							{{ $t(`editAttr.attrText64`) }}
						</div>
						<div class="right-menu-item" @click="handleOrder('bottom')">
							{{ $t(`editAttr.attrText65`) }}
						</div>
					</div>
				</div>
			</div>
			<div class="right-menu3">
				<!-- 标签设置 -->
				<div class="right-menu-item" @click="handleOpenSet()">
					{{ $t(`button.labelset`) }}
				</div>
				<!-- 删除 -->
				<div class="right-menu-item right-menu-del" @click="selectNode ? handleDel() : ''" :style="{ color: selectNode ? '#f73e3e' : '#d6dce6' }">
					<div>{{ $t(`editAttr.attrText66`) }}</div>
					<div>Delete</div>
				</div>
			</div>
		</div>

		<!-- 画布切换 -->
		<div class="canvasSelect-box" v-if="$parent.canvasArr.length > 1">
			<el-icon class="canvasSelect-icon" v-if="!showCanvasSelect" @click="showCanvasSelect = !showCanvasSelect"><ArrowUp /></el-icon>
			<el-icon class="canvasSelect-icon" v-if="showCanvasSelect" @click="showCanvasSelect = !showCanvasSelect" style="margin-bottom: 4px"><ArrowDown /></el-icon>
			<div v-if="!showCanvasSelect" class="canvas-item" @click="showCanvasSelect = !showCanvasSelect">
				<div class="canvas-img-bg">
					<img class="canvas-img" :src="$parent.canvasArr[$parent.canvasIndex]" alt="" />
				</div>
				<div class="canvas-num">{{ $parent.canvasIndex + 1 }} / {{ $parent.canvasArr.length }}</div>
			</div>
			<div v-if="showCanvasSelect">
				<div class="canvas-item" v-for="(img, index) in $parent.canvasArr" :key="index" @click="handleSetCanvas(index)">
					<div
						class="canvas-img-bg"
						:style="{
							background: $parent.canvasIndex == index ? 'rgba(0,53,145,0.1)' : '',
							borderColor: $parent.canvasIndex == index ? '#003591' : ''
						}">
						<img class="canvas-img" :src="img" alt="" />
					</div>
					<div class="canvas-num" :style="{ color: $parent.canvasIndex == index ? '#003591' : '' }">
						{{ index + 1 }}
					</div>
				</div>
			</div>
		</div>

		<!-- 底部菜单 -->
		<div class="print-bottom" :style="{ right: !selectNode ? '20px' : '' }">
			<!-- 新手引导、查看快捷键 -->
			<div class="print-bottom-popover">
				<el-popover placement="top" width="auto" v-model:visible="showHelpPopover">
					<div>
						<div class="print-bottom-shortcutKey-menu" style="border-bottom: 1px solid #d6dce6" @click="(showHelpPopover = false), (showShortcutKey = true)">
							{{ $t(`editAttr.attrText87`) }}
						</div>
						<div class="print-bottom-shortcutKey-menu" @click="(showHelpPopover = false), ($parent.showEditGuide = true)">
							{{ $t(`editAttr.attrText86`) }}
						</div>
					</div>
					<template v-slot:reference>
						<div class="print-bottom-shortcutKey" @click.stop="showShortcutKey = false">
							<div class="iconfont icon-bangzhu"></div>
						</div>
					</template>
				</el-popover>
				<!-- 快捷键说明 -->
				<div class="shortcutKey-menu" v-show="showShortcutKey" @mousedown.stop="">
					<div class="shortcutKey-title">{{ $t(`editAttr.attrText88`) }}</div>
					<div class="shortcutKey-menuMain">
						<div class="shortcutKey-item" v-for="(item, index) in shortcutKey" :key="index">
							<div>{{ item.name }}</div>
							<div class="shortcutKey-item-key">{{ item.key }}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 适应画布、画布缩放 -->
			<div class="print-bottom-btn">
				<!-- 自适应 -->
				<div class="print-bottom-btn-img0">
					<div class="iconfont icon-yibiyi" @click="handleCanvasAdaptive()"></div>
					<div class="print-bottom-hover">
						<div class="print-bottom-hover-text">
							{{ $t(`editAttr.attrText89`) }}
						</div>
						<div class="print-bottom-hover-triangle"></div>
					</div>
				</div>
				<!-- 放大缩小 -->
				<div class="print-bottom-btn-img">
					<div class="iconfont icon-suoxiao" @click="handleScale('decrease')"></div>
					<div class="print-bottom-hover">
						<div class="print-bottom-hover-text">
							{{ $t(`editAttr.attrText90`) }}
						</div>
						<div class="print-bottom-hover-triangle"></div>
					</div>
				</div>
				<el-slider class="print-bottom-btn-slider" v-model="$parent.multiple" :min="0.3" :max="5" :step="0.5" @input="changeCanvasScale"></el-slider>

				<div class="print-bottom-btn-img">
					<div class="iconfont icon-fangda" @click="handleScale('increase')"></div>
					<div class="print-bottom-hover">
						<div class="print-bottom-hover-text">
							{{ $t(`editAttr.attrText91`) }}
						</div>
						<div class="print-bottom-hover-triangle"></div>
					</div>
				</div>
			</div>
		</div>

		<a-modal class="back-box" style="height: 203px" :width="400" centered :visible="backVisible" @cancel="backVisible = false">
			<div style="text-align: center; padding: 10px 24px">
				{{ $t(`tips.tip117`) }}
			</div>
			<template v-slot:footer>
				<a-button @click="$router.back()">{{ $t(`button.abandon`) }}</a-button>
				<a-button @click="handleSaveOk()">{{ $t(`button.save`) }}</a-button>
			</template>
		</a-modal>

		<Rename ref="renameRef" @rename="handleRename" />
		<CoverFile ref="coverFileRef" @refreshFile="refreshFile" />
		<CreateLabel ref="createLabelRef" @set="handleSetLabel" />
	</div>
</template>

<script>
	import axios from 'axios/dist/axios.min.js';
	import { ElMessage, ElMessageBox, ElLoading } from 'element-plus';
	import { ArrowUp, ArrowDown, ArrowRight } from '@element-plus/icons-vue';
	import Rename from '@/components/Rename.vue';
	import CoverFile from '@/components/CoverFile.vue';
	import CreateLabel from '@/components/CreateLabel.vue';
	import { sketchRulerReset } from '../js/sketchRuler.js';
	import { randomNumberStr, qiniuFilename } from '@/utils/util';
	import { getCurrentDates } from '@/utils/formatTime';
	import {
		getTimeFormatList,
		getQrCodeTypeList,
		getBarCodeTypeList,
		getEclevelList,
		getFontFamily,
		resetNodeAttr,
		mmToPx,
		pxToMm,
		getTextAlignValue,
		formatDate,
		combinationData,
		dataPrecision,
		localToBase64,
		dealTimeOffset,
		getNowDateOrTime,
		getGenerateCodeImg,
		randomString,
		getTypeNextNum,
		dealLabelData,
		convertToTen,
		convertToTwentySix,
		setBeforeZero,
		setNodePos
	} from '../js/common.js';
	import { getNodeAttrs, recordHistory, undoStep, restoreStep, recordMultipleHistory } from '../js/history.js';
	import { getEnabledAnchors, KonvaTransformer, updateTransformer, TransformerListening, changeOriginToCenter, changeOriginToTopleft, destroyTransformer } from '../js/transformerStyle';
	import { changeDpiDataUrl } from '../js/changeDpi.js';
	import '../css/edit.css';
	import '../css/edit-small.css';
	import { message } from 'ant-design-vue';
	export default {
		components: {
			Rename,
			CoverFile,
			CreateLabel,
			ArrowUp,
			ArrowDown,
			ArrowRight
		},
		props: {
			designStage: {
				type: Object,
				default: {}
			},
			designArea: {
				type: Object,
				default: {}
			},
			designLayer: {
				type: Object,
				default: {}
			},
			elementGroup: {
				type: Object,
				default: {}
			},
			labelAttr: {
				type: Object,
				default: {}
			},
			elementAttr: {
				type: [Object, String],
				default: () => {}
			},
			historyList: {
				type: Array,
				default: []
			},
			historyIndex: {
				type: Number,
				default: -1
			}
		},
		data() {
			return {
				language: this.$i18n.locale,
				alignList: [
					{
						type: 'left',
						icon: 'icon-zuoduiqi',
						text: this.$t(`editAttr.attrText55`)
					},
					{
						type: 'center',
						icon: 'icon-shuipingjuzhong',
						text: this.$t(`editAttr.attrText58`)
					},
					{
						type: 'right',
						icon: 'icon-youduiqi',
						text: this.$t(`editAttr.attrText57`)
					},
					{
						type: 'top',
						icon: 'icon-dingduiqi',
						text: this.$t(`editAttr.attrText59`)
					},

					{
						type: 'middle',
						icon: 'icon-chizhijuzhong',
						text: this.$t(`editAttr.attrText56`)
					},
					{
						type: 'bottom',
						icon: 'icon-diduiqi',
						text: this.$t(`editAttr.attrText60`)
					},
					{
						type: 'shuipingjianju',
						icon: 'icon-shuipingfenbu',
						text: this.$t(`editAttr.attrText61`)
					},
					{
						type: 'chuizhijianju',
						icon: 'icon-chizhi',
						text: this.$t(`editAttr.attrText62`)
					}
				],
				shortcutKey: [
					{
						name: this.$t(`editAttr.attrText50`),
						key: 'Ctrl+C'
					},
					{
						name: this.$t(`editAttr.attrText51`),
						key: 'Ctrl+V'
					},
					{
						name: this.$t(`editAttr.attrText69`),
						key: 'Ctrl+Z'
					},
					{
						name: this.$t(`editAttr.attrText70`),
						key: 'Ctrl+Y'
					},
					{
						name: this.$t(`editAttr.attrText71`),
						key: 'Ctrl+S'
					},
					{
						name: this.$t(`editAttr.attrText72`),
						key: 'Ctrl+Shift+S'
					},
					{
						name: this.$t(`editAttr.attrText73`),
						key: 'Delete'
					},
					{
						name: this.$t(`editAttr.attrText74`),
						key: 'Up'
					},
					{
						name: this.$t(`editAttr.attrText75`),
						key: 'Down'
					},
					{
						name: this.$t(`editAttr.attrText76`),
						key: 'Left'
					},
					{
						name: this.$t(`editAttr.attrText77`),
						key: 'Right'
					},
					{
						name: this.$t(`editAttr.attrText82`),
						key: 'Ctrl+' + this.$t(`editAttr.attrText85`)
					},
					{
						name: this.$t(`editAttr.attrText78`),
						key: 'Ctrl+A'
					},
					{
						name: this.$t(`editAttr.attrText79`),
						key: 'Ctrl+P'
					},
					{
						name: this.$t(`editAttr.attrText80`),
						key: 'Ctrl+' + this.$t(`editAttr.attrText83`)
					},
					{
						name: this.$t(`editAttr.attrText81`),
						key: 'Ctrl+' + this.$t(`editAttr.attrText84`)
					}
				],
				selectionElement: [],
				showHelpPopover: false,
				showShortcutKey: false, //显示快捷键说明
				multipleSelection: false,
				selectNode: '',
				copyData: [], //存放复制的数据
				mmToPoint: 3.96, // 1mm约等于3.96点 (31.7/8)  (203dpi(pc端采用203dpi进行计算)  1inch=25.4mm   25.4 / (203 / 25.4) = 31.7)
				magnification: 3.78, //1mm 约等于3.78px（96dpi）
				showCanvasSelect: false,
				fileImage: '',
				fileUrl: '',
				saveToLocal: false, //是否另存到本地
				rightMenu: {
					posX: 0,
					posY: 0
				},
				isShowMenu: false, //显示右键菜单
				backVisible: false,
				isBack: false
			};
		},
		watch: {
			elementAttr() {
				if (JSON.stringify(this.elementAttr) === '{}') {
					this.selectNode = '';
				} else {
					this.selectNode = this.designLayer.find('#' + this.elementAttr.Id)[0];
				}
			}
		},
		mounted() {
			//监听滚轮事件  实现画布放大缩小
			document.addEventListener(
				'mousewheel',
				event => {
					//正值向上，负值向下
					if (event.ctrlKey === true || event.metaKey) {
						event.preventDefault();
						if (event.deltaY < 0) {
							// 放大
							this.handleScale('increase');
						} else {
							// 缩小
							this.handleScale('decrease');
						}
					}
				},
				{
					passive: false
				}
			);
			//firefox //监听滚轮事件  实现画布放大缩小
			document.addEventListener(
				'DOMMouseScroll',
				event => {
					//正值向下，负值向上
					if (event.ctrlKey === true || event.metaKey) {
						event.preventDefault();
						if (event.detail > 0) {
							// 放大
							this.handleScale('increase');
						} else {
							// 缩小
							this.handleScale('decrease');
						}
					}
				},
				{
					passive: false
				}
			);
			// 快捷键操作
			document.onkeydown = event => {
				var e = event || window.e;
				// console.log(e);
				var keyCode = e.keyCode || e.which;
				switch (keyCode) {
					// 左移
					case 37:
						this.handleMove('left');
						break;
					// 上移
					case 38:
						this.handleMove('top');
						break;
					// 右移
					case 39:
						this.handleMove('right');
						break;
					// 下移
					case 40:
						this.handleMove('bottom');
						break;
					// 删除
					case 46:
						this.handleDel();
						break;
					// 全选
					case 65:
						if (e.ctrlKey) {
							e.preventDefault();
							this.handleAllSelect();
						}
						break;
					// 复制
					case 67:
						if (e.ctrlKey) {
							this.handleCopy();
						}
						break;
					// 打印
					case 80:
						if (e.ctrlKey) {
							e.preventDefault();
							this.handlePrint();
						}
						break;
					// 保存、另存为
					case 83:
						if (e.ctrlKey) {
							e.preventDefault();
							if (e.shiftKey) {
								//另存为 保存到本地
								this.saveToLocal = true;
								this.handleSave();
							} else {
								// 保存
								this.saveToLocal = false;
								this.handleSave();
							}
						}
						break;
					// 粘贴
					case 86:
						if (e.ctrlKey) {
							this.handlePaste();
						}
						break;
					// 恢复
					case 89:
						if (e.ctrlKey) {
							this.handleRestore();
						}
						break;
					// 撤销
					case 90:
						if (e.ctrlKey) {
							this.handleUndo();
						}
						break;
				}
			};
		},
		methods: {
			//退出编辑器
			handleBack() {
				if (JSON.stringify(this.elementGroup) !== '{}' && this.elementGroup && this.elementGroup.children.length > 0 && this.$parent.isDraw) {
					this.backVisible = true;
				} else {
					this.$router.back();
					this.$parent.isDraw = false;
				}
			},
			//退出编辑器 点击保存
			handleSaveOk() {
				this.backVisible = false;
				this.isBack = true;
				this.handleSave();
			},
			//关闭右键菜单
			closeRightMenu() {
				this.isShowMenu = false;
			},
			//移动元素
			handleMove(type) {
				if (this.multipleSelection) {
					// 多选
					let historyArr = [];
					this.selectionElement.forEach((node, index) => {
						if (!node.attrs.parameter.Lock) {
							let oldAttrs = getNodeAttrs(node);
							let startX = node.attrs.parameter.StartX;
							let startY = node.attrs.parameter.StartY;
							switch (type) {
								case 'left':
									startX -= 1;
									break;
								case 'right':
									startX += 1;
									break;
								case 'top':
									startY -= 1;
									break;
								case 'bottom':
									startY += 1;
									break;
							}
							node.attrs.parameter.StartX = startX;
							node.attrs.parameter.StartY = startY;
							// 转为px单位
							startX = mmToPx(startX, 5);
							startY = mmToPx(startY, 5);
							//根据左上角坐标计算出实际点坐标
							let x = startX;
							let y = startY;
							let rotation = node.rotation();
							let width = node.width() * node.scaleX();
							let height = node.height() * node.scaleY();
							let nodeType = node.attrs.type;

							if (nodeType == 'WinText' || nodeType == 'TimeText' || nodeType == 'GeneralBarcode' || nodeType == 'QRCode' || nodeType == 'Image') {
								x = rotation == 90 ? x + height : rotation == 180 ? x + width : x;
								y = rotation == 180 ? y + height : rotation == -90 ? y + width : y;
							} else if (nodeType == 'Ellipse') {
								x = x + width / 2;
								y = y + height / 2;
							}
							node.x(x);
							node.y(y);
							let newAttrs = getNodeAttrs(node);
							historyArr.push({
								oldAttrs,
								newAttrs,
								type: 'edit'
							});
						}
					});
					this.designLayer.draw();
					recordMultipleHistory(this.$parent, historyArr);
				} else {
					// 单选
					if (!this.selectNode) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					if (this.selectNode.attrs.parameter.Lock) {
						message.error(this.$t(`tips.tip109`));
						return;
					}
					let oldAttrs = getNodeAttrs(this.selectNode);

					let startX = this.selectNode.attrs.parameter.StartX;
					let startY = this.selectNode.attrs.parameter.StartY;

					switch (type) {
						case 'left':
							startX -= 1;
							break;
						case 'right':
							startX += 1;
							break;
						case 'top':
							startY -= 1;
							break;
						case 'bottom':
							startY += 1;
							break;
					}
					this.selectNode.attrs.parameter.StartX = startX;
					this.selectNode.attrs.parameter.StartY = startY;

					// 转为px单位
					startX = mmToPx(startX, 5);
					startY = mmToPx(startY, 5);
					//根据左上角坐标计算出实际点坐标
					let x = startX;
					let y = startY;
					let rotation = this.selectNode.rotation();
					let width = this.selectNode.width() * this.selectNode.scaleX();
					let height = this.selectNode.height() * this.selectNode.scaleY();
					let nodeType = this.selectNode.attrs.type;

					if (nodeType == 'WinText' || nodeType == 'TimeText' || nodeType == 'GeneralBarcode' || nodeType == 'QRCode' || nodeType == 'Image') {
						x = rotation == 90 ? x + height : rotation == 180 ? x + width : x;
						y = rotation == 180 ? y + height : rotation == -90 ? y + width : y;
					} else if (nodeType == 'Ellipse') {
						x = x + width / 2;
						y = y + height / 2;
					}
					this.selectNode.x(x);
					this.selectNode.y(y);

					this.$parent.elementAttr = JSON.parse(JSON.stringify(this.selectNode.attrs.parameter));
					this.designLayer.draw();
					let newAttrs = getNodeAttrs(this.selectNode);
					recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				}
				this.$parent.isDraw = true;
			},
			//全选
			handleAllSelect() {
				destroyTransformer(this.designLayer);
				this.$parent.selectNode = '';
				this.$parent.elementAttr = {};
				this.selectionElement = [];
				let len = this.elementGroup.children.length;
				for (let i = 0; i < len; i++) {
					let node = this.elementGroup.children[i];
					if (node.attrs.parameter.Lock) {
						node.draggable(false);
					} else {
						node.draggable(true);
					}
					this.selectionElement.push(node);
					let transformer = KonvaTransformer([], node);
					this.elementGroup.add(transformer);
				}
				this.multipleSelection = true;
				this.closeRightMenu();
			},
			//打开重命名弹窗
			editLabelName() {
				this.$refs.renameRef.show('label', this.labelAttr);
			},
			//重命名标签
			handleRename(newValue) {
				this.$parent.labelAttr.name = newValue;
			},
			//打开标签设置
			handleOpenSet() {
				this.$refs.createLabelRef.showModal(this.$parent.labelStatus, JSON.parse(JSON.stringify(this.$parent.labelAttr)));
				this.closeRightMenu();
			},
			//标签设置
			handleSetLabel(value) {
				this.$parent.labelAttr = value;
				this.$parent.MeasureUnit = value.unit;
				if (this.$parent.MeasureUnit.toLowerCase() == 'inch') {
					this.$parent.UnitConversion = 25.4;
				} else {
					this.$parent.UnitConversion = 1;
				}
				this.handleCanvasAdaptive();

				//设置背景
				let backgroundImg = this.designLayer.find('#backgroundImg')[0];
				if (this.$parent.labelAttr.background) {
					let image = new Image();
					image.setAttribute('crossOrigin', 'Anonymous');
					image.onload = e => {
						backgroundImg.image(image);
						backgroundImg.attrs.imageSrc = this.$parent.imgHeader + this.$parent.labelAttr.background;
						this.designLayer.draw();
					};
					image.src = this.$parent.imgHeader + this.$parent.labelAttr.background;
				} else {
					backgroundImg.image('');
					this.designLayer.draw();
				}
			},
			// 取消多选
			cancelMultipleSelection() {
				destroyTransformer(this.designLayer);
				if (this.selectionElement.length > 0) {
					this.selectionElement.map(node => {
						node.draggable(false);
					});
				}
				this.designLayer.draw();
				this.multipleSelection = false;
				this.selectionElement = [];
			},
			//锁定 解锁
			handleLock() {
				if (this.multipleSelection) {
					//多选状态
					if (this.selectionElement.length == 0) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					//选中的元素中是否有存在已经被锁定的元素
					let isLock = this.selectionElement.some(node => node.attrs.parameter.Lock);
					//存在锁定元素，将锁定元素解锁
					if (isLock) {
						let historyArr = [];
						this.selectionElement.map(node => {
							let oldAttrs = getNodeAttrs(node);
							node.draggable(true);
							node.attrs.parameter.Lock = false;
							let transformer = this.designLayer.find('.' + node.id())[0];
							transformer.enabledAnchors([]);
							transformer.borderStroke(!node.attrs.parameter.Lock ? '#003591' : '#7B89A1');
							let newAttrs = getNodeAttrs(node);
							historyArr.push({
								oldAttrs,
								newAttrs,
								type: 'edit'
							});
						});
						recordMultipleHistory(this.$parent, historyArr);
					} else {
						let lock = this.selectionElement[0].attrs.parameter.Lock;
						let historyArr = [];
						this.selectionElement.map(node => {
							let oldAttrs = getNodeAttrs(node);
							node.draggable(lock);
							node.attrs.parameter.Lock = !lock;
							let transformer = this.designLayer.find('.' + node.id())[0];
							transformer.enabledAnchors([]);
							transformer.borderStroke(!node.attrs.parameter.Lock ? '#003591' : '#7B89A1');
							let newAttrs = getNodeAttrs(node);
							historyArr.push({
								oldAttrs,
								newAttrs,
								type: 'edit'
							});
						});
						recordMultipleHistory(this.$parent, historyArr);
					}
					this.$forceUpdate();
					this.designLayer.draw();
				} else {
					//单选状态
					if (!this.selectNode) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					let oldAttrs = getNodeAttrs(this.selectNode);

					this.selectNode.attrs.parameter.Lock = !this.selectNode.attrs.parameter.Lock;
					this.selectNode.draggable(!this.selectNode.attrs.parameter.Lock);
					let Transformer = this.designLayer.find('.' + this.selectNode.id())[0];
					Transformer.borderStroke(!this.selectNode.attrs.parameter.Lock ? '#003591' : '#7B89A1');
					Transformer.enabledAnchors(getEnabledAnchors(this.selectNode));
					this.designLayer.draw();
					this.$forceUpdate();
					let newAttrs = getNodeAttrs(this.selectNode);
					recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				}
				this.$parent.isDraw = true;
				this.closeRightMenu();
			},
			//撤销
			handleUndo() {
				if (this.historyIndex < 0) return;
				this.cancelMultipleSelection();
				undoStep(this.$parent);
				this.$parent.isDraw = true;
			},
			//恢复
			handleRestore() {
				if (this.historyIndex == this.historyList.length - 1 || this.historyList.length == 0) return;
				this.cancelMultipleSelection();
				restoreStep(this.$parent);
				this.$parent.isDraw = true;
			},
			//对齐
			handleAlign(value) {
				if (value == 'chuizhijianju') {
					this.handleVerticalSpace();
					return;
				} else if (value == 'shuipingjianju') {
					this.handleHorizontalSpace();
					return;
				}

				if (this.multipleSelection) {
					//多选状态
					if (this.selectionElement.length == 0) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					if (this.selectionElement.length < 2) {
						//只存在一个元素
						let oldAttrs = getNodeAttrs(this.selectionElement[0]);
						const { x, y } = this.singleAlign(this.selectionElement[0], value);
						this.selectionElement[0].setAttrs({ x, y });
						this.designLayer.draw();
						let newAttrs = getNodeAttrs(this.selectionElement[0]);
						recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
					} else {
						this.multipleAlign(value);
					}
				} else {
					//单选状态
					if (!this.selectNode) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					if (this.selectNode.attrs.parameter.Lock) {
						message.error(this.$t(`tips.tip109`));
						return;
					}
					let oldAttrs = getNodeAttrs(this.selectNode);
					const { x, y } = this.singleAlign(this.selectNode, value);
					this.selectNode.setAttrs({ x, y });
					setNodePos(this.selectNode);
					this.$parent.elementAttr = JSON.parse(JSON.stringify(this.selectNode.attrs.parameter));
					this.designLayer.draw();
					let newAttrs = getNodeAttrs(this.selectNode);
					recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				}
				this.$parent.isDraw = true;
				this.closeRightMenu();
			},
			//单一元素对齐
			singleAlign(node, value) {
				let type = node.attrs.type;
				let x = node.x();
				let y = node.y();
				let areaWidth = this.designArea.width();
				let areaHeight = this.designArea.height();
				let width = node.width() * node.scaleX();
				let height = node.height() * node.scaleX();
				let rotation = node.rotation();
				if (type == 'Ellipse') {
					//椭圆的x,y在图行中心点
					width /= 2;
					height /= 2;
				}
				switch (value) {
					case 'left':
						if (type == 'Ellipse') {
							x = width;
						} else {
							x = rotation == 90 ? height : rotation == 180 ? width : 0;
						}
						break;
					case 'center':
						if (type == 'Ellipse') {
							x = (areaWidth - width * 2) / 2 + width;
						} else {
							x = rotation == 90 ? (areaWidth - height) / 2 + height : rotation == 180 ? (areaWidth - width) / 2 + width : rotation == -90 ? (areaWidth - height) / 2 : (areaWidth - width) / 2;
						}
						break;
					case 'right':
						x = rotation == 90 || rotation == 180 ? areaWidth : rotation == -90 ? areaWidth - height : areaWidth - width;
						break;
					case 'top':
						if (type == 'Ellipse') {
							y = height;
						} else {
							y = rotation == -90 ? width : rotation == 180 ? height : 0;
						}
						break;
					case 'middle':
						if (type == 'Ellipse') {
							y = (areaHeight - height * 2) / 2 + height;
						} else {
							y = rotation == 90 ? (areaHeight - width) / 2 : rotation == 180 ? (areaHeight - height) / 2 + height : rotation == -90 ? (areaHeight - width) / 2 + width : (areaHeight - height) / 2;
						}
						break;
					case 'bottom':
						y = rotation == 180 || rotation == -90 ? areaHeight : rotation == 90 ? areaHeight - width : areaHeight - height;
						break;
				}
				return { x, y };
			},
			//多个元素对齐
			multipleAlign(value) {
				let { minX, minY, maxX, maxY, maxW, maxH } = this.selectionElement.reduce((prev, current) => {
					// 将旋转角度转换为弧度
					let angle = (current.rotation() * Math.PI) / 180;
					// 计算旋转后的四个角的坐标
					let x1 = current.x() + Math.cos(angle) * 0 - Math.sin(angle) * 0;
					let y1 = current.y() + Math.sin(angle) * 0 + Math.cos(angle) * 0;
					let x2 = current.x() + Math.cos(angle) * current.width() * current.scaleX() - Math.sin(angle) * 0;
					let y2 = current.y() + Math.sin(angle) * current.width() * current.scaleX() + Math.cos(angle) * 0;
					let x3 = current.x() + Math.cos(angle) * current.width() * current.scaleX() - Math.sin(angle) * current.height() * current.scaleY();
					let y3 = current.y() + Math.sin(angle) * current.width() * current.scaleX() + Math.cos(angle) * current.height() * current.scaleY();
					let x4 = current.x() + Math.cos(angle) * 0 - Math.sin(angle) * current.height() * current.scaleY();
					let y4 = current.y() + Math.sin(angle) * 0 + Math.cos(angle) * current.height() * current.scaleY();

					// 计算旋转后的最小x,y值,最大x,y值
					let rotatedData = {
						rotatedMinX: Math.min(x1, x2, x3, x4),
						rotatedMinY: Math.min(y1, y2, y3, y4),
						rotatedMaxX: Math.max(x1, x2, x3, x4),
						rotatedMaxY: Math.max(y1, y2, y3, y4)
					};
					rotatedData['width'] = rotatedData.rotatedMaxX - rotatedData.rotatedMinX;
					rotatedData['height'] = rotatedData.rotatedMaxY - rotatedData.rotatedMinY;

					let result = {
						minX: prev.minX < rotatedData.rotatedMinX ? prev.minX : rotatedData.rotatedMinX,
						minY: prev.minY < rotatedData.rotatedMinY ? prev.minY : rotatedData.rotatedMinY,
						maxX: prev.maxX > rotatedData.rotatedMaxX ? prev.maxX : rotatedData.rotatedMaxX,
						maxY: prev.maxY > rotatedData.rotatedMaxY ? prev.maxY : rotatedData.rotatedMaxY,
						maxW: {
							x: prev.maxW && prev.maxW.w > rotatedData.width ? prev.maxW.x : rotatedData.rotatedMinX,
							w: prev.maxW && prev.maxW.w > rotatedData.width ? prev.maxW.w : rotatedData.width
						},
						maxH: {
							y: prev.maxH && prev.maxH.h > rotatedData.height ? prev.maxH.y : rotatedData.rotatedMinY,
							h: prev.maxH && prev.maxH.h > rotatedData.height ? prev.maxH.h : rotatedData.height
						}
					};
					return result;
				}, Infinity);
				// console.log(minX, minY, maxX, maxY, maxW, maxH)
				let historyArr = [];
				this.selectionElement.forEach(node => {
					if (!node.attrs.parameter.Lock) {
						let oldAttrs = getNodeAttrs(node);
						let rotation = node.rotation();
						let x = node.x();
						let y = node.y();
						let width = node.width() * node.scaleX();
						let height = node.height() * node.scaleY();
						switch (value) {
							case 'left':
								x = rotation == 90 ? minX + height : rotation == 180 ? minX + width : minX;
								break;
							case 'center':
								x =
									rotation == 90
										? maxW.x + maxW.w / 2 + height / 2
										: rotation == 180
										? maxW.x + maxW.w / 2 + width / 2
										: rotation == -90
										? maxW.x + maxW.w / 2 - height / 2
										: maxW.x + maxW.w / 2 - width / 2;
								break;
							case 'right':
								x = rotation == 0 ? maxX - width : rotation == -90 ? maxX - height : maxX;
								break;
							case 'top':
								y = rotation == 180 ? minY + height : rotation == -90 ? minY + width : minY;
								break;
							case 'middle':
								y =
									rotation == 90
										? maxH.y + maxH.h / 2 - width / 2
										: rotation == 180
										? maxH.y + maxH.h / 2 + height / 2
										: rotation == -90
										? maxH.y + maxH.h / 2 + width / 2
										: maxH.y + maxH.h / 2 - height / 2;
								break;
							case 'bottom':
								y = rotation == 0 ? maxY - height : rotation == 90 ? maxY - width : maxY;
								break;
						}
						node.setAttrs({ x, y });
						setNodePos(node);
						let newAttrs = getNodeAttrs(node);
						historyArr.push({
							oldAttrs,
							newAttrs,
							type: 'edit'
						});
					}
				});
				this.designLayer.draw();
				recordMultipleHistory(this.$parent, historyArr);
			},
			//垂直间距
			handleVerticalSpace() {
				if (this.selectionElement.length < 3) return;
				// 按照节点顶部Y坐标从小到大对所有节点进行排序
				this.selectionElement.sort((node1, node2) => {
					let node1_w = node1.width() * node1.scaleX();
					let node1_h = node1.height() * node1.scaleY();
					let y1 = node1.rotation() == 180 ? node1.y() - node1_h : node1.rotation() == -90 ? node1.y() - node1_w : node1.y();

					let node2_w = node2.width() * node2.scaleX();
					let node2_h = node2.height() * node2.scaleY();
					let y2 = node2.rotation() == 180 ? node2.y() - node2_h : node2.rotation() == -90 ? node2.y() - node2_w : node2.y();

					return y1 - y2;
				});
				// console.log('排序后', this.selectionElement);

				// 查找出底部Y坐标最大值的节点
				let lastNode = this.selectionElement.reduce((pre, cur) => {
					let pre_w = pre.width() * pre.scaleX();
					let pre_h = pre.height() * pre.scaleY();
					let pre_maxY = pre.rotation() == 0 ? pre.y() + pre_h : pre.rotation() == 90 ? pre.y() + pre_w : pre.y();

					let cur_w = cur.width() * cur.scaleX();
					let cur_h = cur.height() * cur.scaleY();
					let cur_maxY = cur.rotation() == 0 ? cur.y() + cur_h : cur.rotation() == 90 ? cur.y() + cur_w : cur.y();
					return pre_maxY < cur_maxY ? cur : pre;
				});
				// console.log('底部Y坐标最大值的节点', lastNode);
				// firstNode为顶部Y坐标最小值的节点，即selectionElement中的第一个元素
				let firstNode = this.selectionElement[0];

				// console.log('firstNode', firstNode);
				// console.log('lastNode', lastNode);

				// 设置间距值
				let space = 0;
				//起始坐标
				let currentY = 0;
				let nodeCount = 0;
				// 比较firstNode和lastNode是否为同个元素
				if (firstNode.id() == lastNode.id()) {
					// 去除掉该节点，该节点不参与排序
					this.selectionElement.shift();
					// 取去除后的数组中的第一个节点作为firstNode
					firstNode = this.selectionElement[0];
					// lastNode置空
					lastNode = '';
					// space设置为固定值
					// space设置为固定值
					space = firstNode.rotation() == 90 || firstNode.rotation() == -90 ? firstNode_h * -0.75 : firstNode_w * -0.75;

					let firstNode_w = firstNode.width() * firstNode.scaleX();
					let firstNode_h = firstNode.height() * firstNode.scaleY();

					// firstNode底部Y坐标
					let firstNodeBottomY = firstNode.rotation() == 0 ? firstNode.y() + firstNode_h : firstNode.rotation() == 90 ? firstNode.y() + firstNode_w : firstNode.y();

					currentY = firstNodeBottomY;
					// 重置除第一个元素的值
					nodeCount = this.selectionElement.length;
				} else {
					// 获取lastNode在数组中的下标
					let index = this.selectionElement.findIndex(node => node.id() == lastNode.id());
					//将数组中该节点删除，并push到最后一个元素
					this.selectionElement.splice(index, 1);
					this.selectionElement.push(lastNode);

					let firstNode_w = firstNode.width() * firstNode.scaleX();
					let firstNode_h = firstNode.height() * firstNode.scaleY();

					// firstNode底部Y坐标
					let firstNodeBottomY = firstNode.rotation() == 0 ? firstNode.y() + firstNode_h : firstNode.rotation() == 90 ? firstNode.y() + firstNode_w : firstNode.y();

					let lastNode_w = lastNode.width() * lastNode.scaleX();
					let lastNode_h = lastNode.height() * lastNode.scaleY();

					// lastNode顶部Y坐标
					let lastNodeTopY = lastNode.rotation() == 180 ? lastNode.y() - lastNode_h : lastNode.rotation() == -90 ? lastNode.y() - lastNode_w : lastNode.y();

					// lastNode的顶部Y坐标 - firstNode的底部Y坐标
					let bottomToTop_h = lastNodeTopY - firstNodeBottomY;
					//计算所有中间节点的高度之和
					let allNodeHeight = 0;
					for (let i = 1; i < this.selectionElement.length - 1; i++) {
						let node = this.selectionElement[i];
						let nodeHeight = node.rotation() == 90 || node.rotation() == -90 ? node.width() * node.scaleX() : node.height() * node.scaleY();
						allNodeHeight += nodeHeight;
					}

					// 计算间距数量
					let middleNodeCount = this.selectionElement.length - 1;
					space = (bottomToTop_h - allNodeHeight) / middleNodeCount;

					currentY = firstNodeBottomY;

					// 重置除第一个元素和最后一个元素以内的值
					nodeCount = this.selectionElement.length - 1;
				}

				// console.log('currentY', currentY);
				// console.log('space', space);
				let historyArr = [];
				for (let i = 1; i < nodeCount; i++) {
					let node = this.selectionElement[i];
					let oldAttrs = getNodeAttrs(node);
					currentY += space; //左上角Y坐标
					let w = node.width() * node.scaleX();
					let h = node.height() * node.scaleY();
					let y = node.rotation() == 180 ? currentY + h : node.rotation() == -90 ? currentY + w : currentY;
					node.y(y);
					if (node.rotation() == 90 || node.rotation() == -90) {
						currentY += w;
					} else {
						currentY += h;
					}
					setNodePos(node);
					let newAttrs = getNodeAttrs(node);
					historyArr.push({
						oldAttrs,
						newAttrs,
						type: 'edit'
					});
				}
				this.designLayer.draw();
				recordMultipleHistory(this.$parent, historyArr);
			},
			//水平间距
			handleHorizontalSpace() {
				if (this.selectionElement.length < 3) return;
				// 按照节点左侧X坐标从小到大对所有节点进行排序
				this.selectionElement.sort((node1, node2) => {
					let node1_w = node1.width() * node1.scaleX();
					let node1_h = node1.height() * node1.scaleY();
					let x1 = node1.rotation() == 90 ? node1.x() - node1_h : node1.rotation() == 180 ? node1.x() - node1_w : node1.x();

					let node2_w = node2.width() * node2.scaleX();
					let node2_h = node2.height() * node2.scaleY();
					let x2 = node2.rotation() == 90 ? node2.x() - node2_h : node2.rotation() == 180 ? node2.x() - node2_w : node2.x();

					return x1 - x2;
				});
				// console.log('排序后', this.selectionElement);

				// 查找出右侧X坐标最大值的节点
				let lastNode = this.selectionElement.reduce((pre, cur) => {
					let pre_w = pre.width() * pre.scaleX();
					let pre_h = pre.height() * pre.scaleY();
					let pre_maxX = pre.rotation() == 0 ? pre.x() + pre_w : pre.rotation() == -90 ? pre.x() + pre_h : pre.x();

					let cur_w = cur.width() * cur.scaleX();
					let cur_h = cur.height() * cur.scaleY();
					let cur_maxX = cur.rotation() == 0 ? cur.x() + cur_w : cur.rotation() == -90 ? cur.x() + cur_h : cur.x();
					return pre_maxX < cur_maxX ? cur : pre;
				});
				// console.log('右侧X坐标最大值的节点', lastNode, lastNode);
				// firstNode为左侧X坐标最小值的节点，即selectionElement中的第一个元素
				let firstNode = this.selectionElement[0];

				// console.log('firstNode', firstNode.id());
				// console.log('lastNode', lastNode.id());

				// 设置间距值
				let space = 0;
				//起始坐标
				let currentX = 0;
				let nodeCount = 0;
				// 比较firstNode和lastNode是否为同个元素
				if (firstNode.id() == lastNode.id()) {
					// 去除掉该节点，该节点不参与排序
					this.selectionElement.shift();
					// 取去除后的数组中的第一个节点作为firstNode
					firstNode = this.selectionElement[0];
					// lastNode置空
					lastNode = '';
					// console.log('lastNode', lastNode);

					let firstNode_w = firstNode.width() * firstNode.scaleX();
					let firstNode_h = firstNode.height() * firstNode.scaleY();

					// firstNode右侧X坐标
					let firstNodeRightX = firstNode.rotation() == 0 ? firstNode.x() + firstNode_w : firstNode.rotation() == -90 ? firstNode.x() + firstNode_h : firstNode.x();

					// space设置为固定值
					space = firstNode.rotation() == 90 || firstNode.rotation() == -90 ? firstNode_h * -0.75 : firstNode_w * -0.75;

					currentX = firstNodeRightX;
					// 重置除第一个元素的值
					nodeCount = this.selectionElement.length;
				} else {
					// 获取lastNode在数组中的下标
					let index = this.selectionElement.findIndex(node => node.id() == lastNode.id());
					//将数组中该节点删除，并push到最后一个元素
					this.selectionElement.splice(index, 1);
					this.selectionElement.push(lastNode);

					let firstNode_w = firstNode.width() * firstNode.scaleX();
					let firstNode_h = firstNode.height() * firstNode.scaleY();

					// firstNode右侧X坐标
					let firstNodeRightX = firstNode.rotation() == 0 ? firstNode.x() + firstNode_w : firstNode.rotation() == -90 ? firstNode.x() + firstNode_h : firstNode.x();

					let lastNode_w = lastNode.width() * lastNode.scaleX();
					let lastNode_h = lastNode.height() * lastNode.scaleY();

					// lastNode左侧X坐标
					let lastNodeLeftX = lastNode.rotation() == 90 ? lastNode.x() - lastNode_h : lastNode.rotation() == 180 ? lastNode.x() - lastNode_w : lastNode.x();

					// lastNode的左侧X坐标 - firstNode的右侧X坐标
					let rightToLeft_w = lastNodeLeftX - firstNodeRightX;
					//计算所有中间节点的宽度之和
					let allNodeWidth = 0;
					for (let i = 1; i < this.selectionElement.length - 1; i++) {
						let node = this.selectionElement[i];
						let nodeWidth = node.rotation() == 90 || node.rotation() == -90 ? node.height() * node.scaleY() : node.width() * node.scaleX();
						allNodeWidth += nodeWidth;
					}

					// 计算间距数量
					let middleNodeCount = this.selectionElement.length - 1;
					space = (rightToLeft_w - allNodeWidth) / middleNodeCount;

					currentX = firstNodeRightX;

					// 重置除第一个元素和最后一个元素以内的值
					nodeCount = this.selectionElement.length - 1;
				}

				// console.log('currentX', currentX);
				// console.log('space', space);

				let historyArr = [];
				for (let i = 1; i < nodeCount; i++) {
					let node = this.selectionElement[i];
					let oldAttrs = getNodeAttrs(node);
					currentX += space; //左上角X坐标
					// console.log(node.x(), currentX);
					let w = node.width() * node.scaleX();
					let h = node.height() * node.scaleY();
					let x = node.rotation() == 90 ? currentX + h : node.rotation() == 180 ? currentX + w : currentX;
					node.x(x);
					if (node.rotation() == 90 || node.rotation() == -90) {
						currentX += h;
					} else {
						currentX += w;
					}
					setNodePos(node);
					let newAttrs = getNodeAttrs(node);
					historyArr.push({
						oldAttrs,
						newAttrs,
						type: 'edit'
					});
				}
				this.designLayer.draw();
				recordMultipleHistory(this.$parent, historyArr);
			},
			//置顶置底
			handleOrder(type) {
				if (this.multipleSelection) {
					//多选状态
					if (this.selectionElement.length == 0) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					let historyArr = [];
					let index = 0;
					switch (type) {
						case 'top':
							index = this.elementGroup.children.length - 2;
							this.selectionElement.forEach(node => {
								if (!node.attrs.parameter.Lock) {
									let oldAttrs = getNodeAttrs(node);
									node.setZIndex(index);
									this.designLayer.batchDraw();
									index--;
									let newAttrs = getNodeAttrs(node);
									historyArr.push({
										oldAttrs,
										newAttrs,
										type: 'edit'
									});
								}
							});
							break;
						case 'bottom':
							index = 0;
							this.selectionElement.forEach(node => {
								if (!node.attrs.parameter.Lock) {
									let oldAttrs = getNodeAttrs(node);
									node.setZIndex(index);
									this.designLayer.batchDraw();
									index++;
									let newAttrs = getNodeAttrs(node);
									historyArr.push({
										oldAttrs,
										newAttrs,
										type: 'edit'
									});
								}
							});
							break;
					}

					this.$forceUpdate();
					recordMultipleHistory(this.$parent, historyArr);
				} else {
					if (!this.selectNode) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					if (this.selectNode.attrs.parameter.Lock) {
						message.error(this.$t(`tips.tip109`));
						return;
					}
					let oldAttrs = getNodeAttrs(this.selectNode);
					//单选
					switch (type) {
						case 'top':
							let index = this.elementGroup.children.length - 2;
							this.selectNode.zIndex(index);
							break;
						case 'bottom':
							this.selectNode.zIndex(0);
							break;
					}
					this.designLayer.draw();
					let newAttrs = getNodeAttrs(this.selectNode);
					recordHistory(this.$parent, oldAttrs, newAttrs, 'edit');
				}
				this.$parent.isDraw = true;
				this.closeRightMenu();
			},
			//复制
			handleCopy() {
				const getCloneNode = node => {
					//复制模板，解除事件绑定
					let newNode = node.clone();
					newNode.off();
					return newNode;
				};

				if (this.multipleSelection) {
					//多选状态
					if (this.selectionElement.length == 0) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					this.copyData = [];
					this.selectionElement.forEach(node => {
						if (!node.attrs.parameter.Lock) {
							let newNode = getCloneNode(node);
							this.copyData.push(newNode);
						}
					});
					this.designLayer.draw();
					message.success(this.$t(`tips.tip35`));
				} else {
					//单选状态
					if (!this.selectNode) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					if (this.selectNode.attrs.parameter.Lock) {
						message.error(this.$t(`tips.tip109`));
						return;
					}
					this.copyData = [];
					let newNode = getCloneNode(this.selectNode);
					this.copyData.push(newNode);
					this.designLayer.draw();
					message.success(this.$t(`tips.tip35`));
				}
				this.closeRightMenu();
			},
			//粘贴
			handlePaste() {
				if (this.copyData.length == 0) return;

				const setCloneNode = node => {
					let newNode = node.clone();
					newNode.off();
					let parameter = JSON.parse(JSON.stringify(node.attrs.parameter));
					newNode.setAttrs({
						x: node.x() + 20,
						y: node.y() + 20,
						id: randomString(8),
						draggable: false,
						parameter
					});
					if (newNode.attrs.type == 'WinText' || newNode.attrs.type == 'TimeText') {
						//解决镜像情况下文本位置偏移的问题
						if (newNode.attrs.parameter.TextArrange == 1) {
							let textGroup = newNode.find('.textGroup')[0];
							if (textGroup) {
								if (newNode.attrs.parameter.MirrorImage) {
									let width = textGroup.width();
									textGroup.x(width);
								} else {
									textGroup.x(0);
								}
							}
						} else {
							if (newNode.attrs.parameter.MirrorImage) {
								let width = newNode.getText().width();
								newNode.getText().x(width);
							} else {
								newNode.getText().x(0);
							}
						}
					}
					this.elementGroup.add(newNode);
					newNode.attrs.parameter.Id = newNode.id();
					newNode.attrs.parameter.Name = `${node.attrs.type == 'GeneralBarcode' ? 'BarCode' : node.attrs.type}${getTypeNextNum(this.elementGroup, node.attrs.type)}`;
					TransformerListening(this.$parent, newNode);
					node.x(newNode.x());
					node.y(newNode.y());
					return newNode;
				};

				this.cancelMultipleSelection();

				destroyTransformer(this.designLayer);
				this.$parent.selectNode = '';
				this.$parent.elementAttr = {};
				let historyArr = [];

				this.copyData.forEach(node => {
					let newNode = setCloneNode(node);
					setNodePos(newNode);

					let newAttrs = getNodeAttrs(newNode);
					historyArr.push({
						oldAttrs: '',
						newAttrs,
						type: 'add'
					});
				});
				recordMultipleHistory(this.$parent, historyArr);
				this.closeRightMenu();
				this.$parent.isDraw = true;
			},
			//删除
			handleDel() {
				let oldAttrs = '';
				if (this.multipleSelection) {
					//多选状态
					if (this.selectionElement.length == 0) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					destroyTransformer(this.designLayer);
					let historyArr = [];
					let recordIds = [];
					for (let i = this.selectionElement.length - 1; i > -1; i--) {
						let node = this.selectionElement[i];
						let oldAttrs = getNodeAttrs(node);
						recordIds.push(node.id());
						node.destroy();
						historyArr.push({
							oldAttrs,
							newAttrs: '',
							type: 'del'
						});
					}
					recordMultipleHistory(this.$parent, historyArr);
					this.selectionElement = [];
					this.multipleSelection = false;
					this.delAssociate(recordIds);
					this.designLayer.draw();
					this.selectNode = '';
					this.$parent.selectNode = '';
					this.$parent.elementAttr = {};
					this.closeRightMenu();
					this.$parent.isDraw = true;
				} else {
					//单选状态
					if (!this.selectNode) {
						message.error(this.$t(`tips.tip108`));
						return;
					}
					oldAttrs = getNodeAttrs(this.selectNode);

					let recordIds = [this.selectNode.id()];
					let isReferenced = this.elementGroup.children.some(node => {
						if (node.attrs.type == 'WinText' || node.attrs.type == 'GeneralBarcode' || node.attrs.type == 'QRCode') {
							return node.attrs.parameter.DataSourceList.some(val => val.DataSourceType == 3 && val.ReferenceId == this.selectNode.id());
						}
					});
					if (isReferenced) {
						//该元素有被其他元素关联
						ElMessageBox.confirm(this.$t(`tips.tip77`), this.$t(`tips.tip71`), {
							confirmButtonText: this.$t(`button.confirm`),
							cancelButtonText: this.$t(`button.cancel`),
							cancelButtonClass: 'messageCancelBtn',
							confirmButtonClass: 'messageConfirmBtn',
							type: 'warning'
						})
							.then(() => {
								this.selectNode.destroy();
								destroyTransformer(this.designLayer);
								recordHistory(this.$parent, oldAttrs, '', 'del');
								this.delAssociate(recordIds);
								this.designLayer.draw();
								this.selectNode = '';
								this.$parent.selectNode = '';
								this.$parent.elementAttr = {};
								this.closeRightMenu();
								this.$parent.isDraw = true;
							})
							.catch(() => {
								return;
							});
					} else {
						destroyTransformer(this.designLayer);
						this.selectNode.destroy();
						recordHistory(this.$parent, oldAttrs, '', 'del');
						this.designLayer.draw();
						this.selectNode = '';
						this.$parent.selectNode = '';
						this.$parent.elementAttr = {};
						this.closeRightMenu();
						this.$parent.isDraw = true;
					}
				}
			},
			// 删除元素时解除关联关系 并将类型修改为普通文本
			async delAssociate(recordIds) {
				for (let i = 0; i < this.elementGroup.children.length; i++) {
					let node = this.elementGroup.children[i];
					if (node.attrs.type == 'WinText' || node.attrs.type == 'GeneralBarcode' || node.attrs.type == 'QRCode') {
						node.attrs.parameter.DataSourceList.forEach(val => {
							let isFind = recordIds.some(id => id == val.ReferenceId);
							if (isFind) {
								val.ReferenceId = '';
								val.DataSourceType = 0;
								val.FirstValue = val.NormalText ? val.NormalText : 'Label designer';
							}
						});
					}
				}
			},
			//画布自适应 重置
			handleCanvasAdaptive() {
				let width = this.$parent.labelAttr.width * this.$parent.UnitConversion * this.magnification;
				let height = this.$parent.labelAttr.height * this.$parent.UnitConversion * this.magnification;
				let scaleX = (this.$parent.canvasWidth * 0.6) / width;
				let scaleY = (this.$parent.canvasHeight * 0.6) / height;
				let multiple = scaleX < scaleY ? scaleX : scaleY;
				this.$parent.multiple = Math.round(multiple * 10) / 10;

				//元素属性框的宽度+边距
				let clientWidth = document.body.clientWidth;
				let attrBoxWidth = clientWidth <= 1280 ? 320 : 476;
				let x = -(width - (this.$parent.canvasWidth - attrBoxWidth)) / 2 + width / 2;
				let y = -(height - this.$parent.canvasHeight) / 2 + height / 2;

				x = x < 50 + (width * this.multiple) / 2 ? 50 + (width * this.multiple) / 2 : x;

				this.designArea.setAttrs({
					x,
					y,
					offsetX: width / 2,
					offsetY: height / 2,
					width,
					height,
					scaleX: this.$parent.multiple,
					scaleY: this.$parent.multiple
				});
				let background = this.designArea.find('#background')[0];
				background.setAttrs({
					width,
					height
				});
				let backgroundImg = this.designArea.find('#backgroundImg')[0];
				backgroundImg.setAttrs({
					width,
					height
				});
				let backgroundCanvas = this.designLayer.find('#backgroundCanvas')[0];
				backgroundCanvas.setAttrs({
					width,
					height
				});
				this.elementGroup.setAttrs({
					width,
					height
				});
				this.designLayer.draw();
				sketchRulerReset(this.$parent);
			},
			//放大缩小
			handleScale(type) {
				switch (type) {
					case 'decrease':
						if (this.$parent.multiple <= 0.3) return;
						this.$parent.multiple -= 0.1;
						break;
					case 'increase':
						if (this.$parent.multiple >= 5) return;
						this.$parent.multiple += 0.1;
						break;
				}
				this.changeCanvasScale();
			},
			//移动滑块，缩放画布
			changeCanvasScale() {
				this.$parent.multiple = Number(this.$parent.multiple.toFixed(1));
				this.designArea.scaleX(this.$parent.multiple);
				this.designArea.scaleY(this.$parent.multiple);
				this.designLayer.draw();
				sketchRulerReset(this.$parent);
			},
			//切换画布
			handleSetCanvas(index) {
				let backgroundCanvas = this.designLayer.find('#backgroundCanvas')[0];
				let image = new Image();
				image.setAttribute('crossOrigin', 'Anonymous');
				image.onload = e => {
					backgroundCanvas.image(image);
					backgroundCanvas.attrs.imageSrc = this.$parent.canvasArr[index];
					this.$parent.canvasIndex = index;
					this.designLayer.draw();
					// this.showCanvasSelect = false;
				};
				image.src = this.$parent.canvasArr[index];
			},
			//点击打印
			async handlePrint() {
				if (this.elementGroup.children.length == 0) {
					message.error(this.$t(`tips.tip50`));
					return;
				}
				this.$parent.loadingInstance = ElLoading.service({
					lock: true,
					text: 'Loading',
					background: 'rgba(0, 0, 0, 0.2)'
				});
				//取消选中
				destroyTransformer(this.designLayer);
				const cellSelectArr = this.designLayer.find('.cellSelect');
				cellSelectArr.map(item => {
					item.hide();
				});

				//取消多选
				this.cancelMultipleSelection();

				this.designLayer.draw();
				this.selectNode = '';
				this.$parent.selectNode = '';

				let { multipleNodeData, minCount } = this.getMultipleNode();

				let highPixelImg = await this.generatePicture();
				// console.log(highPixelImg);
				let labelList = await dealLabelData(this.elementGroup.children);
				// console.log(labelList);
				this.$parent.loadingInstance.close();
				let obj = {
					type: 'edit',
					highPixelImg,
					labelAttr: this.$parent.labelAttr,
					labelList,
					multipleNodeData,
					minCount,
					canvasImg: this.$parent.canvasArr[this.$parent.canvasIndex]
				};
				this.$parent.$refs.PrintLabelRef.show(obj);
			},
			//处理递变数据和excel表格数据
			getMultipleNode() {
				let multipleNodeData = [];
				let countArr = [];
				let minCount = 1;
				let nodeInfo = [];
				//单元格排序
				const dealTableCell = (cell_1, cell_2) => {
					if (cell_1.Key[0] !== cell_2.Key[0]) return cell_1.Key[0] < cell_2.Key[0] ? -1 : 1;
					else if (cell_1.Key[1] !== cell_2.Key[1]) return cell_1.Key[1] < cell_2.Key[1] ? -1 : 1;
				};
				//获取最小长度
				this.elementGroup.children.forEach(node => {
					let type = node.attrs.type;
					if (type == 'WinText' || type == 'GeneralBarcode' || type == 'QRCode' || type == 'Table') {
						let DataSourceList = node.attrs.parameter.DataSourceList;
						if (type == 'Table') {
							DataSourceList.sort(dealTableCell);
						}
						let arr = [];
						let isMult = false;
						DataSourceList.forEach((item, index) => {
							let DataSourceType = item.DataSourceType;
							if (DataSourceType == 1 || DataSourceType == 2) {
								isMult = true;
								if (DataSourceType == 1) {
									//数据库
									if (item.ConnectionData && item.ConnectionData.length > 0) {
										countArr.push(item.ConnectionData.length);
									}
								} else {
									countArr.push(Number(item.Quantity));
								}
								arr.push(index); //DataSourceList下标
							}
						});
						if (isMult) {
							nodeInfo.push({
								id: node.id(), //元素id
								indexArr: arr
							});
						}
					}
				});

				if (countArr.length > 0) {
					//获取最小长度
					minCount = Math.min(...countArr);
					// console.log('minCount', minCount);

					nodeInfo.forEach(info => {
						let node = this.elementGroup.find(`#${info.id}`)[0];
						if (node) {
							let parameter = node.attrs.parameter;
							let DataSourceList = node.attrs.parameter.DataSourceList;
							let arr = [];
							for (let i = 0; i < info.indexArr.length; i++) {
								let dataSourceIndex = info.indexArr[i];
								let data = DataSourceList[dataSourceIndex];
								let DataSourceType = data.DataSourceType;
								if (DataSourceType == 1) {
									//数据库
									arr.push({
										dataSourceIndex,
										data: data.ConnectionData.slice(0, minCount)
									});
								} else if (DataSourceType == 2) {
									//序列化
									let SequenceType = data.SequenceType;
									let valArr = [];
									for (let j = 0; j < minCount; j++) {
										let sequenceValue = '';
										let value = '';
										if (SequenceType == 1) {
											//十六进制
											sequenceValue = (parseInt(data.FirstValue, 16) + data.AddedValue * j).toString(16);
											value = setBeforeZero(16, sequenceValue, data.FirstValue);
										} else if (SequenceType == 2) {
											//二十六进制
											sequenceValue = convertToTwentySix(convertToTen(data.FirstValue) + data.AddedValue * j);
											value = setBeforeZero(26, sequenceValue, data.FirstValue);
										} else if (SequenceType == 3) {
											//三十六进制
											sequenceValue = (parseInt(data.FirstValue, 36) + data.AddedValue * j).toString(36);
											value = setBeforeZero(36, sequenceValue, data.FirstValue);
										} else {
											//十进制
											sequenceValue = (data.FirstValue * 1 + data.AddedValue * j).toString();
											value = setBeforeZero(10, sequenceValue, data.FirstValue);
										}
										valArr.push(value);
									}
									arr.push({
										dataSourceIndex,
										data: valArr
									});
								}
							}
							multipleNodeData.push({
								id: info.id,
								name: parameter.Name,
								data: arr
							});
						}
					});
					multipleNodeData = this.dealReferenceData(multipleNodeData);
				}
				return { multipleNodeData, minCount };
			},
			//处理数据库序列化关联元素打印
			dealReferenceData(multipleNodeData) {
				const traverseArray = (dataArray, dataSourceList) => {
					let result = [];
					for (let i = 0; i < dataArray[0].data.length; i++) {
						let combinedValue = '';
						for (let j = 0; j < dataSourceList.length; j++) {
							let source = dataSourceList[j];
							if (source.DataSourceType == 0) {
								//普通文本
								combinedValue += source.FirstValue;
							} else if (source.DataSourceType == 1 || source.DataSourceType == 2) {
								let data = dataArray.find(val => val.dataSourceIndex == j);
								combinedValue += data.data[i];
							}
						}
						result.push(combinedValue);
					}

					return result;
				};
				this.elementGroup.children.forEach(node => {
					if (node.attrs.type == 'WinText' || node.attrs.type == 'GeneralBarcode' || node.attrs.type == 'QRCode') {
						let parameter = node.attrs.parameter;
						let arr = [];
						parameter.DataSourceList.forEach((val, dataSourceIndex) => {
							if (val.DataSourceType == 3) {
								//存在元素值，且元素值绑定的值为递变数据或数据库数据
								let reference_index = multipleNodeData.findIndex(item => item.id == val.ReferenceId);
								let reference_node = this.elementGroup.find(`#${multipleNodeData[reference_index].id}`)[0];
								let reference_DataSourceList = reference_node.attrs.parameter.DataSourceList;

								if (reference_index > -1) {
									arr.push({
										dataSourceIndex,
										data: traverseArray(multipleNodeData[reference_index].data, reference_DataSourceList)
									});
								}
							}
						});
						if (arr.length > 0) {
							multipleNodeData.push({
								id: node.id(),
								name: parameter.Name,
								data: arr
							});
						}
					}
				});
				return multipleNodeData;
			},
			//点击保存
			async handleSave() {
				if (this.elementGroup.children.length == 0) {
					message.error(this.$t(`tips.tip50`));
					return;
				}
				this.$parent.loadingInstance = ElLoading.service({
					lock: true,
					text: 'Loading',
					background: 'rgba(0, 0, 0, 0.2)'
				});
				//取消选中
				destroyTransformer(this.designLayer);
				const cellSelectArr = this.designLayer.find('.cellSelect');
				cellSelectArr.map(item => {
					item.hide();
				});

				//取消多选
				this.cancelMultipleSelection();

				this.designLayer.draw();
				this.selectNode = '';
				this.$parent.selectNode = '';

				try {
					let labelList = await dealLabelData(this.elementGroup.children);
					// console.log('labelList', labelList);

					let backgroundImg = this.designLayer.find('#backgroundImg')[0];
					var json = {
						'?xml': {
							'@version': '1.0',
							'@encoding': 'utf-8'
						},
						PrtLable: {
							'#comment': [],
							FileInfo: {
								Creator: {
									'@Platform': 'Web',
									'@Version': this.$Version
								}
							},
							PictureArea: {
								AreaSize: {
									'@Width': Math.round(this.$parent.labelAttr.width * this.$parent.UnitConversion * this.mmToPoint),
									'@Height': Math.round(this.$parent.labelAttr.height * this.$parent.UnitConversion * this.mmToPoint)
								},
								LabelPage: {
									'@MeasureUnit': this.$parent.MeasureUnit,
									'@LabelShape': 'Rectangle',
									'@Width': this.$parent.labelAttr.width,
									'@Height': this.$parent.labelAttr.height,
									Rows: this.$parent.labelAttr.rows,
									Columns: this.$parent.labelAttr.columns,
									RowSpacing: this.$parent.labelAttr.rowSpacing,
									ColumnSpacing: this.$parent.labelAttr.columnSpacing,
									LeftMargin: this.$parent.labelAttr.paddingLeft,
									RightMargin: this.$parent.labelAttr.paddingRight,
									UpperMargin: this.$parent.labelAttr.paddingTop,
									LowerMargin: this.$parent.labelAttr.paddingBottom,
									LabelWidth: this.$parent.labelAttr.width,
									LabelHeight: this.$parent.labelAttr.height,
									Background: backgroundImg.attrs.imageSrc ? backgroundImg.attrs.imageSrc.split('base64,')[1] : '',
									PrintBackground: 'False',
									Canvas: this.$parent.canvasArr.length > 0 ? this.$parent.canvasArr.join(',') : ''
								}
							},
							ObjectList: {
								'@Count': labelList.length,
								DrawObject: labelList
							}
						}
					};
					await this.qinNiuApi();
					this.loadFile(json);
				} catch (err) {
					console.log(err);
				}
			},
			//生成图片
			generatePicture() {
				return new Promise(async (resolve, reject) => {
					this.$parent.sketchRulerLayer.hide();
					let dataURL = this.designStage.toDataURL({
						x: this.designArea.x() - (this.designArea.width() * this.designArea.scaleX()) / 2,
						y: this.designArea.y() - (this.designArea.height() * this.designArea.scaleY()) / 2,
						width: this.designArea.width() * this.designArea.scaleX(),
						height: this.designArea.height() * this.designArea.scaleY(),
						quality: 1,
						pixelRatio: 3,
						mimeType: 'image/png'
					});
					let highPixelImg = changeDpiDataUrl(dataURL, 300);
					this.$parent.sketchRulerLayer.show();
					resolve(highPixelImg);
				});
			},
			//上传预览图到七牛云
			qinNiuApi() {
				return new Promise(async (resolve, reject) => {
					let saveKey = `hm/${this.$parent.userInfo.userid}/tprt/preview/${getNowDateOrTime('date')}/${qiniuFilename()}.png`;
					saveKey = window.btoa(saveKey);
					let config = {
						headers: {
							Authorization: 'UpToken ' + this.$parent.postData.token
						},
						custom: { loading: true }
					};
					let highPixelImg = await this.generatePicture();
					let urlBase = highPixelImg.split('base64,')[1];
					axios
						.post(this.$picupload + saveKey, urlBase, config)
						.then(res => {
							this.fileImage = res.data.key;
							resolve();
						})
						.catch(err => {
							reject(err);
						});
				});
			},
			//json转xml 获取文件路径
			loadFile(json) {
				var form = new FormData();
				let data = JSON.stringify(json);
				// data = btoa(unescape(encodeURIComponent(data)));

				form.append('data', data);
				form.append('type', 0); //是否加密   0不加密 1加密

				this.$axiosnet('/LoadXml/loadfile', form)
					.then(async res => {
						this.fileUrl = res;
						this.saveLabel();
					})
					.catch(err => {});
			},
			//保存标签
			saveLabel() {
				if (this.saveToLocal) {
					//另存为本地
					window.open(this.fileUrl.windows, '_parent');
					if (this.$parent.loadingInstance) {
						this.$parent.loadingInstance.close();
					}
					this.saveAsToOther = false;
					return;
				}
				//common为行业模板
				let parent_id =
					this.$parent.labelAttr.type == 'common' ? this.$parent.userClassList[0].id : this.$parent.labelAttr.parent_id ? this.$parent.labelAttr.parent_id : this.$parent.userClassList[0].id;
				let obj = {
					name: this.$parent.labelAttr.name ? this.$parent.labelAttr.name : randomString(8),
					user_id: this.$parent.userInfo.userid,
					parent_id,
					width: this.$parent.labelAttr.width,
					height: this.$parent.labelAttr.height,
					url: this.fileUrl.windows,
					image: this.fileImage,
					platform: 2,
					windows: this.fileUrl.windows,
					android: this.fileUrl.android,
					remark: this.$parent.labelAttr.remark ? this.$parent.labelAttr.remark : ''
				};
				if (this.$parent.labelStatus == 'edit' && this.$parent.labelAttr.type != 'common') {
					obj.id = this.$parent.labelAttr.id;
					this.$axioshanma('/api/tem.app_public/editUserTemplate', obj)
						.then(res => {
							message.success(this.$t(`tips.tip58`));
							this.refreshFile(res);
						})
						.catch(err => {});
				} else {
					this.$axioshanma('/api/tem.app_public/addUserTemplate', obj)
						.then(res => {
							message.success(this.$t(`tips.tip58`));
							this.refreshFile(res);
						})
						.catch(err => {
							console.log(err);
							if (err.code == 402) {
								if (process.env.VUE_APP_VERSION == 'hprt') {
									this.$parent.$refs.MemberDiaRef.show(err.message);
								} else {
									message.error(err.message);
								}
							} else if (err.code == 405) {
								//模板名称重复
								this.$refs.coverFileRef.show(obj);
							}
						});
				}
			},
			//覆盖文件成功后刷新数据 或退出页面
			refreshFile(data) {
				if (this.isBack) {
					this.$router.back();
				} else {
					this.$parent.isDraw = false;
					let params = {
						id: data.id,
						parent_id: data.parent_id,
						name: data.name,
						file_pc: data.file_pc,
						height: data.height,
						width: data.width,
						type: 'cloud'
					};
					this.$parent.labelAttr = Object.assign(this.$parent.labelAttr, params);
					this.$parent.labelStatus = 'edit';
					this.$router.replace({
						path: '/editLabel',
						query: {
							labelAttr: encodeURIComponent(JSON.stringify(params)),
							labelStatus: 'edit'
						}
					});
				}
			}
		}
	};
</script>

<style>
	.messageCancelBtn:hover {
		color: #003591;
		background: rgba(0, 53, 145, 0.1);
		border: 1px solid rgba(0, 53, 145, 0.1);
	}

	.messageConfirmBtn {
		background: #003591;
		border: 1px solid #003591;
	}

	.messageConfirmBtn:hover {
		background: rgba(0, 53, 145, 0.9);
		border: 1px solid rgba(0, 53, 145, 0.9);
	}

	.back-box .ant-modal-header {
		padding-top: 0;
		margin-left: 0;
		height: 0;
	}

	.back-box .ant-modal-body {
		padding: 0;
		padding-top: 71px;
		font-size: 20px;
		font-weight: 500;
		color: #1a1d21;
		line-height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100px;
	}
	.back-box .ant-btn:last-child {
		background: #003591;
		color: #ffffff;
		border: none;
	}
	@media only screen and (max-width: 1280px) {
		.back-box .ant-modal-body {
			padding: 0;
			padding-top: 47px;
			font-size: 14px;
			line-height: 28px;
		}
	}
</style>
